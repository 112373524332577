import React, { useState } from 'react'
import {
    authorizedToCrudAnnouncementsAction, classDataAction, goodLoginAction, isActiveAction, isAdminAction, isAlumniAction,
    isSuperUserAction, resetPasswordAction, showMenuNavbarButtonAction, signedInUserIdAction, tpsClassAction,
    userIdOfBioToEditAction, userIdOfBioToShowAction, usernameAction, userTypeAction
} from "../Redux/Actions"
import axios from "axios"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid"
import { Redirect } from "react-router-dom"
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import Snackbar from "../Atoms/Snackbar"
import { store } from "../Redux/Store"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

const Login = () => {

   const [ checkBox, setCheckBox ] = useState(false)
   const [ goodLogin, setGoodLogin ] = useState(false)
   const [ isActive, setIsActive ] = useState(false)
   const [ password, setPassword ] = useState('')
   const [ resetPassword, setResetPassword ] = useState(false)
   const [ sendToAddGradPage, setSendToAddGradPage ] = useState(false)
   const [ sendToLandingPage, setSendToLandingPage ] = useState(false)
   const [ snackBarMsg, setSnackBarMsg ] = useState('')
   const [ snackBarOpen, setSnackBarOpen ] = useState(false)
   const [ snackBarType, setSnackBarType ] = useState('')
   const [ username, setUsername ] = useState('')
   const [ userType, setUserType ] = useState('')

   // Updates state for password and username
   const handlePasswordChange = (e) => {
      setPassword(e.target.value)
   }

   const handleUsernameChange = (e) => {
      setUsername(e.target.value)
   }

   //
   const handleCheckboxChange = () => {
      setTimeout( () => {
         setCheckBox(!checkBox)
      }, 1000 )
   }

   const handleSubmit = async (e) => {
      e.preventDefault()

      store.dispatch(usernameAction(username))

      const tpsClass = username.split('.')

      if (tpsClass[2]) {
         /* This is for when a new user is logging in for the first time. There would be no TPS Class
            populated for them if I put this action below in the .then() where it used to be. This move
            supports the PersonalAndClassStats Component. */
         store.dispatch(tpsClassAction(tpsClass[2].toLowerCase()))

         await axios.get(node_server + `classLeaderAwardData/${tpsClass[2].toLowerCase()}`)
         .then( res => {
               if (res.data.classData) {
                  store.dispatch(classDataAction(res.data.classData))
               }
               else {
                  /* The { } is so classData is an object where keys can be checked and
                     if not there can be used as a boolean */
                  store.dispatch(classDataAction({}))
               }
         } )
      }

      var loginFormData = new FormData()

      loginFormData.append("password", password)
      loginFormData.append("username", username)

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.post(node_server + 'login', {
         password,
         username
      })
         .then(res => res.data)
         .then(data => {
               if (data.goodLogin) {

                  setGoodLogin(true)
                  setUserType(data.userType)

                  store.dispatch(signedInUserIdAction(data.userId))
                  store.dispatch(userIdOfBioToEditAction(data.userId))
                  store.dispatch(userIdOfBioToShowAction(data.userId))
                  store.dispatch(userTypeAction(data.userType))
                  store.dispatch(authorizedToCrudAnnouncementsAction(data.crudAnnouncements))

                  // store.dispatch(storeJwtAction(data.JWT))

                  /*
                  * Commented out after moving bios completely to MongoDB
                  */
                  // To store to control which Bio options show in MENU
                  // if (data.hasBio) {
                  //     store.dispatch(hasBioAction(true))
                  // } else {
                  //     store.dispatch(hasBioAction(false))
                  // }

                  // Send userType to store
                  store.dispatch(goodLoginAction(true))
                  if (data.userType === "admin") {
                     store.dispatch(isAdminAction(true))
                  }
                  else if (data.userType === "alumni") {
                     store.dispatch(isAlumniAction(true))
                  }
                  else if (data.userType === "superUser") {
                     store.dispatch(isSuperUserAction(true))
                     store.dispatch(showMenuNavbarButtonAction(true))
                  }

                  // Comment out per issue #204
                  // // To store to control if the Hamburger shows the Add Class Page option
                  // // Also send TPS Class to the store for use when CRUD on a Class Page
                  // // The else piece is needed even though the default value in the store is FALSE
                  // // in case two people use the same computer and browser. A true does not get reset
                  // // without the ELSE
                  // // console.log('Login Component tpsClass is ', data.tpsClass)
                  // if(data.classPage){
                  //     store.dispatch(classPageAction(true))
                  //     store.dispatch(tpsClassAction(data.tpsClass))
                  // }
                  // else{
                  //     store.dispatch(classPageAction(false))
                  //     store.dispatch(tpsClassAction(data.tpsClass))
                  // }

                  // If user is NOT active
                  if (!data.isActive) {

                     setSnackBarMsg("Good Login! Please reset your password")
                     setSnackBarType('success')
                     setSnackBarOpen(true)
                     setPassword('')

                     setTimeout(() => {
                           setSnackBarMsg('')
                           setSnackBarOpen(false)

                           store.dispatch(resetPasswordAction(true))
                           setResetPassword(true)
                     }, 2500)
                     // If user IS active
                  } else {
                     setGoodLogin(true)
                     setIsActive(true)
                     if (data.sendToAddGradPage) {
                           setSendToAddGradPage(true)
                     }
                     else if (data.sendToLandingPage) {
                           setSendToLandingPage(true)
                           store.dispatch(showMenuNavbarButtonAction(true))
                     }
                     store.dispatch(isActiveAction(true))
                  }
                  // Bad Login
               } else {
                  setSnackBarMsg(data.msg)
                  setSnackBarType('error')
                  setSnackBarOpen(true)
                  setPassword('')
                  setUsername('')

                  setTimeout(() => {
                     setSnackBarOpen(false)
                  }, 2500)
               }
         })
   }

   return (
      <Box display='flex' justifyContent='center' style={{ marginTop: '10rem'}}>
         {goodLogin && userType === 'superUser' ?
            <Redirect to="/Analytics" />
            : null
         }
         {goodLogin && isActive && sendToAddGradPage ? <Redirect to="/AddGrad" /> : null}
         {goodLogin && isActive && sendToLandingPage ? <Redirect to="/Search" /> : null}
         {goodLogin && resetPassword ? <Redirect to="/ResetPassword" /> : null}

            <Grid container className='zztop' style={{
               border: 'solid', borderColor: 'lightgray', borderRadius: '4px', borderWidth: '1px', boxShadow: '1px 1px 1px lightgray',
               width: '25%'
            }}>
               <Grid container justifyContent='flex-start' style={{marginTop: '1rem', marginLeft: '1rem'}}>
                  <Typography variant='h5'>
                     <strong>
                        Login
                     </strong>
                  </Typography>
               </Grid>
               <Grid container justifyContent='flex-start' style={{marginTop: '10px'}}>
                  <Grid item lg={12} style={{marginLeft: '1rem', marginRight: '1rem'}}>
                     <TextField label="Username" name="username" variant='filled' style={{width: '100%'}} value={username} onChange={handleUsernameChange} autoFocus={true} />
                  </Grid>

                  <Grid item lg={12} style={{marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem'}}>
                     <TextField label="Password" name="password" variant='filled' style={{width: '100%'}} type="password" value={password} onChange={handlePasswordChange} />
                  </Grid>

                     <Grid container item display='flex' direction='row' alignItems='center' justifyContent='center' lg={12} style={{marginTop: '10px'}} >
                           <Typography variant='h6'>
                              I am not a robot
                           </Typography>
                           <Checkbox color='primary' onChange={handleCheckboxChange}/>
                     </Grid>

                  {password.length > 0 && username.length > 0 && checkBox ?
                     <Grid container item justifyContent='flex-end' lg={12} style={{marginLeft: '1rem', marginRight: '1rem', marginTop: '10px', marginBottom: '16px'}}>
                           <Button
                              variant="outlined"
                              color="primary"
                              onClick={handleSubmit}
                              style={{transition: 'all 1.5s ease-out'}}
                           >
                              Login
                           </Button>
                     </Grid>
                     :
                     <Grid container item justifyContent='flex-end' lg={12} style={{marginLeft: '1rem', marginRight: '1rem', marginTop: '10px', marginBottom: '16px'}}>
                           <Button variant="outlined" color="primary" disabled onClick={handleSubmit}>
                              Login
                           </Button>
                     </Grid>
                  }
               </Grid>

               {/* <Grid container justify='center' alignItems='center' style={{marginTop: '2rem'}}>
                  <Grid item lg={12} style={{marginTop: '1rem'}}>
                     <Link to="ForgotUsernamePassword" >
                           <Typography >
                              Forgot Username?
                           </Typography>
                     </Link>
                  </Grid>
               </Grid> */}

            </Grid>
            {snackBarOpen ?
               <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                  <Grid item sm={6}>
                        <Snackbar msg={snackBarMsg} type={snackBarType} />
                  </Grid>
               </Grid>
               : null
            }
      </Box>
   )
}
export default Login