/*
 * Top level component for Yearbook Admin, lots of similarities with <YearbookDisplayPage />
 */
import React, { useCallback, useEffect, useState } from 'react'
import AllGradsFromTblAuth from '../Components/AllGradsFromTblAuth'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import ClassSelectTwoWays from '../Components/ClassSelectTwoWays'
import DisplayYbClassPages from '../yb/DisplayYbClassPages'
import { getTPSClassesFromNodeServer } from '../Helpers'
import Grid from '@material-ui/core/Grid'
import { node_server } from '../Helpers'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { store } from '../Redux/Store'
import { tpsClassAction, userIdAuthorizedToCrudWeeklyUpdateAction } from '../Redux/Actions'
import YbDecadeButtons from './YbDecadeButtons'
// import YearbookShowAllClassOptions from './YearbookShowAllClassOptions'

const YearbookTPSClasses = props => {

  const [ allGrads, setAllGrads ] = useState([])
  const [ classPatches, setClassPatches ] = useState([])
  const [ searchString, setSearchString ] = useState('')
  const [ show1940s, setShow1940s ] = useState(false)
  const [ show1950s, setShow1950s ] = useState(false)
  const [ show1960s, setShow1960s ] = useState(false)
  const [ show1970s, setShow1970s ] = useState(false)
  const [ show1980s, setShow1980s ] = useState(false)
  const [ show1990s, setShow1990s ] = useState(false)
  const [ show2000s, setShow2000s ] = useState(false)
  const [ show2010s, setShow2010s ] = useState(false)
  const [ show2020s, setShow2020s ] = useState(false)
  const [ showAllClasses, setShowAllClasses ] = useState(false)
  const [ showAllClassOptions, setShowAllClassOptions ] = useState(false)
  const [ showClassDecades, setShowClassDecades ] = useState(false)
  const [ showClassErrorMessage, setShowClassErrorMessage ] = useState(false)
  const [ showClassOptions, setShowClassOptions ] = useState(false)
  const [ tempTpsClass, setTempTpsClass ] = useState('')
  const [ tpsClasses, setTpsClasses ] = useState([])
  const [ userIdAuthorizedToCrudWeeklyUpdateArray, setUserIdAuthorizedToCrudWeeklyUpdate ] = useState(0)

  const getAllGrads = useCallback((gradsFromAllGrads) => {
    setAllGrads(gradsFromAllGrads)
  }, [] )

  const getSelectedClass = (c) => {
    setTempTpsClass('')
    setShowAllClassOptions(false)
    setSearchString(c)
    store.dispatch( tpsClassAction(c) )
    submitTpsClass(c)
  }

  const submitTpsClass = (c) => {
    // Find the index of event.target.value in order to know which userIdAuthorizedToCrudWeeklyUpdate
    // to select from userIdAuthorizedToCrudWeeklyUpdateArray and send that to the store
        store.dispatch(tpsClassAction(c))
        const tpsClassIndex = tpsClasses.findIndex( s => s === c )
        store.dispatch(userIdAuthorizedToCrudWeeklyUpdateAction(userIdAuthorizedToCrudWeeklyUpdateArray[tpsClassIndex]))
  }

  const handleClassTextEntry = (e) => {
    setTempTpsClass(e.target.value.toLocaleLowerCase())
  }

  useEffect( () => {
    if (tempTpsClass.length === 5) {
        if (!tpsClasses.includes(tempTpsClass)) {
          setShowClassErrorMessage(true)
          setSearchString('')
          store.dispatch( tpsClassAction('') )
          submitTpsClass('')
        }
        else {
          setShowClassErrorMessage(false)
          setSearchString(tempTpsClass)
          store.dispatch( tpsClassAction(tempTpsClass) )
          submitTpsClass(tempTpsClass)
        }
    }
  }, [tempTpsClass])

  const handleShow1940s = () => {
    setSearchString('')
    setSearchString('194')
    setShowAllClasses(true)
    setShow1940s(!show1940s)
    setShowClassDecades(false)
  }

  const handleShow1950s = () => {
    setSearchString('')
    setSearchString('195')
    setShowAllClasses(true)
    setShow1950s(!show1950s)
    setShowClassDecades(false)
  }

  const handleShow1960s = () => {
    setSearchString('')
    setSearchString('196')
    setShowAllClasses(true)
    setShow1960s(!show1960s)
    setShowClassDecades(false)
  }

  const handleShow1970s = () => {
    setSearchString('')
    setSearchString('197')
    setShowAllClasses(true)
    setShow1970s(!show1970s)
    setShowClassDecades(false)
  }

  const handleShow1980s = () => {
    setSearchString('')
    setSearchString('198')
    setShowAllClasses(true)
    setShow1980s(!show1980s)
    setShowClassDecades(false)
  }

  const handleShow1990s = () => {
    setSearchString('')
    setSearchString('199')
    setShowAllClasses(true)
    setShow1990s(!show1990s)
    setShowClassDecades(false)
  }

  const handleShow2000s = () => {
    setSearchString('')
    setSearchString('200')
    setShowAllClasses(true)
    setShow2000s(!show2000s)
    setShowClassDecades(false)
  }

  const handleShow2010s = () => {
    setSearchString('')
    setSearchString('201')
    setShowAllClasses(true)
    setShow2010s(!show2010s)
    setShowClassDecades(false)
  }

  const handleShow2020s = () => {
    setSearchString('')
    setSearchString('202')
    setShowAllClasses(true)
    setShow2020s(!show2020s)
    setShowClassDecades(false)
  }

  const handleShowAllClasses = () => {
    setSearchString('')
    setSearchString('allClasses')
  }

  const handleShowAllClassOptions = () => {
    setShow1940s(false)
    setShowClassDecades(false)
    setShowAllClassOptions(!showAllClassOptions)
    setSearchString('')
  }

  const handleShowClassOptions = () => {
    setShowAllClasses(true)
    setShowClassDecades(false)
    setShowClassOptions(!showClassOptions)
    setSearchString('')
  }

  const handleShowTPSDecades = () => {
    setSearchString('')
    setShowClassDecades(!showClassDecades)
    setShow1940s(false)
    setShowAllClassOptions(false)
  }

  useEffect(() => {
    if (showClassOptions) {
      getTPSClassesFromNodeServer()
        .then( res => {
        setTpsClasses(res)
      })
    }
  }, [showClassOptions])

  /* GET ALL class patches */
  useEffect(() => {
    axios.get(node_server + 'classPatch/GetAllClassPatches')
      .then( res => {
        /* Array of in the format of:
           {
             _id:,
             tpsClass: "2020a",
             classPatchFilename
           } */
        setClassPatches(res.data)
      })
  }, [])

  return (
    <Grid>

      <Grid container justifyContent='flex-start'>
        <Button onClick={handleShowClassOptions}>
          <Paper style={{backgroundColor: 'lightgray'}}>
            <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
              TPS Classes
            </Typography>
          </Paper>
        </Button>
      </Grid>

      {showClassOptions ?
        <Grid container>
          <Grid>
            <Button onClick={handleShowAllClasses}>
              <Paper style={{backgroundColor: 'lightgray'}}>
                <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                  All Classes
                </Typography>
              </Paper>
            </Button>
          </Grid>
          <Grid>
            <Button onClick={handleShowTPSDecades}>
              <Paper style={{backgroundColor: 'lightgray'}}>
                <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                  Classes By Decade
                </Typography>
              </Paper>
            </Button>
          </Grid>
          <Grid>
            <Button onClick={handleShowAllClassOptions}>
              <Paper style={{backgroundColor: 'lightgray'}}>
                <Typography variant='h4' style={{color: 'black', padding: '0.5rem'}}>
                  Select A Class
                </Typography>
              </Paper>
            </Button>
          </Grid>
        </Grid>
        : null
      }

      {showAllClasses ?
        <AllGradsFromTblAuth getAllGrads={getAllGrads} searchString={searchString} />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1940s} decade='1944 - 1949' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1950s} decade='1950 - 1959' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1960s} decade='1960 - 1969' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1970s} decade='1970 - 1979' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1980s} decade='1980 - 1989' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow1990s} decade='1990 - 1999' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow2000s} decade='2000 - 2009' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow2010s} decade='2010 - 2019' />
        : null
      }

      {showClassDecades ?
        <YbDecadeButtons handleDecade={handleShow2020s} decade='2020 - Present' />
        : null
      }

      {/* {showAllClassOptions ?
        <YearbookShowAllClassOptions getSelectedClass={getSelectedClass} tpsClasses={tpsClasses} />
        : null
      } */}

      {showAllClassOptions ?
        <ClassSelectTwoWays getSelectedClass={getSelectedClass} handleClassTextEntry={handleClassTextEntry} tempTpsClass={tempTpsClass} tpsClasses={tpsClasses} />
        : null
      }

      {showClassErrorMessage ?
        <Typography variant='h5' style={{color: 'black', padding: '0.5rem'}}>
          That TPS Class Is Not A Valid Selection
        </Typography>
        : null
      }

      {searchString !== '' ?
        <DisplayYbClassPages allGrads={allGrads} classPatches={classPatches} />
        : null
      }

    </Grid>
  )
}

export default YearbookTPSClasses
