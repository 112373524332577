/* This is called by
 * <ManagePhotos />
 *    <ManageBioPhotos />
 *       <ShowBioPhotoToManage />
*/
import React                              from 'react'
import ArrowDownwardIcon                  from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon                    from '@material-ui/icons/ArrowUpward';
import Box                                from '@material-ui/core/Box'
import Container                          from '@material-ui/core/Container'
import Grid                               from '@material-ui/core/Grid'
import IconButton                         from '@material-ui/core/IconButton'
import PropTypes                          from 'prop-types'
import Typography                         from '@material-ui/core/Typography'

const ShowBioPhotoToManage = ({bio, getPhotoIdToEdit, movePhotoEntryDown, movePhotoEntryUp}) => {

   let bioPhotosToDisplay = []

   const handleEdit = (id) => {
      getPhotoIdToEdit(id)
   }

   const handleMoveUp = (index) => {
      movePhotoEntryUp(index)
   }

   const handleMoveDown = (index) => {
      movePhotoEntryDown(index)
   }

   if( bio.bioPhoto ){
      bioPhotosToDisplay = bio.bioPhoto.map( (bioPhoto, index, bioArray) => {

         /* This is to make sure photos work for either locally soruced files or AWS files while the
         photo transition to AWS is happening. */
         const filename    = bioPhoto.bioPhotoFilename.split('://')[0] === 'https'  ? bioPhoto.bioPhotoFilename : `/images/${bio.userId}/${bioPhoto.bioPhotoFilename}`
         const filename_a  = bioPhoto.bioPhotoFilename.slice(0,4) === '9999'        ? bioPhoto.bioPhotoFilename : `/images/${bioPhoto.bioPhotoFilename}`

         return(<Grid container direction='row' key={bioPhoto._id} style={{marginBottom: '2rem'}} >
               <Grid container item xs={3} direction='row' style={{marginTop: '1rem', marginRight: '2rem'}} >
                  {/* This allows for multipls bios to use the same photo that resides in the 9999 image director */}
                  {bioPhoto.bioPhotoFilename.slice(0,4) === '9999'
                     ?
                     <img
                           style={{width: '100%'}}
                           alt={`${bio.lastName} ${index + 1}`}
                           src={filename_a}
                     />
                     :
                     <img
                           style={{width: '100%'}}
                           alt={`${bio.lastName} ${index + 1}`}
                           src={filename}
                     />
                  }
               </Grid>
               <Grid container item xs={6} direction='row' alignItems='center'>
                  <Typography variant='h6' >
                     { bioPhoto.bioPhotoCaption }
                  </Typography>
               </Grid>
               <Grid container item xs={1} alignItems='center'>
                  <IconButton onClick={() => handleEdit(bioPhoto._id)}>
                     <Typography variant='h6'>
                           Edit
                           </Typography>
                  </IconButton>
               </Grid>
               <Grid container item xs={1}>
                  {index === 0 ?
                  <IconButton disabled>
                     <ArrowUpwardIcon />
                  </IconButton>
                  :
                  <IconButton onClick={ () => handleMoveUp(index) }>
                     <ArrowUpwardIcon />
                  </IconButton>
                  }
                  { index === bioArray.length - 1 ?
                  <IconButton disabled>
                     <ArrowDownwardIcon />
                  </IconButton>
                  :
                  <IconButton onClick={ () => handleMoveDown(index) }>
                     <ArrowDownwardIcon />
                  </IconButton>
                  }
               </Grid>
         </Grid>)
      } )
   }

   return (
      <Box style={{marginTop: '3rem'}} >
         <Container>
               { bioPhotosToDisplay }
         </Container>
      </Box>
   )
}

ShowBioPhotoToManage.propTypes = {
    bio: PropTypes.object,
    getPhotoIdToEdit: PropTypes.func,
    movePhotoEntryDown: PropTypes.func,
    movePhotoEntryUp: PropTypes.func
}

export default ShowBioPhotoToManage