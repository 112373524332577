/* This is called by
 * 
 * <ManagePhotos />
 *    <ManageClassPatches />
 *       <ShowClassPatchesToManage />
*/
import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowClassPatchesToManage = ({classPatch, getPatchIdToEdit}) => {

   const { tpsClass } = classPatch

   var classPatchesToDisplay = []

   const handleEdit = (id) => {
      getPatchIdToEdit(id)
   }

  if( classPatch.classPatchFilename ){
      classPatchesToDisplay = 
         <Grid container direction='row' key={classPatch._id} style={{marginBottom: '2rem'}} >
               <Grid container item xs={3} direction='row' style={{marginTop: '1rem', marginRight: '2rem'}} >
                  {classPatch.classPatchFilename.startsWith('https://')
                  ?
                     <img
                           style={{width: '100%'}}
                           alt={`${classPatch.tpsClass} ${1}`}
                           src={`${classPatch.classPatchFilename}`}
                     />
                  :
                     <img
                           style={{width: '100%'}}
                           alt={`${classPatch.tpsClass} ${1}`}
                           src={`/images/class-patches/${tpsClass}/${classPatch.classPatchFilename}`}
                     />
                  }
               </Grid>
               <Grid container item xs={6} direction='row' alignItems='center'>
                  <Typography variant='h6' >
                     { classPatch.classPatchCaption }
                  </Typography>
               </Grid>
               <Grid container item xs={1} alignItems='center'>
                  <IconButton onClick={() => handleEdit(classPatch._id)}>
                     <Typography variant='h6'>
                           Edit
                           </Typography>
                  </IconButton>
               </Grid>
         </Grid>
   }

   return (
      <Box>
         <Container>
               { classPatchesToDisplay }
         </Container>
      </Box>
   )
}

ShowClassPatchesToManage.propTypes = {
    classPatch: PropTypes.object,
    getPatchIdToEdit: PropTypes.func
}

export default ShowClassPatchesToManage
