/* This is called by
 * 
 * <ManagePhotos />
 *    <ManageClassPhotos />
 *       <ShowClassPhotosToManage />
*/

import React from 'react'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const ShowClassPhotoToManage = ({classPhoto, getPhotoIdToEdit, movePhotoEntryDown, movePhotoEntryUp}) => {

   const { tpsClass } = classPhoto

   var classPhotosToDisplay = []

   const handleEdit = (id) => {
      getPhotoIdToEdit(id)
   }

   const handleMoveUp = (index) => {
      movePhotoEntryUp(index)
   }

   const handleMoveDown = (index) => {
      movePhotoEntryDown(index)
   }

   if( classPhoto.classPhoto ){
      classPhotosToDisplay = classPhoto.classPhoto.map( (classPhoto, index, classPhotoArray) => {
         return(<Grid container direction='row' key={classPhoto._id} style={{marginBottom: '2rem'}} >
               <Grid container item xs={3} direction='row' style={{marginTop: '1rem', marginRight: '2rem'}} >
                  {classPhoto.classPhotoFilename.startsWith('https://')
                  ?
                     <img
                           style={{width: '100%'}}
                           alt={`${classPhoto.tpsClass} ${index + 1}`}
                           src={`${classPhoto.classPhotoFilename}`}
                     />
                  :
                     <img
                           style={{width: '100%'}}
                           alt={`${classPhoto.tpsClass} ${index + 1}`}
                           src={`/images/class-pictures/${tpsClass}/${classPhoto.classPhotoFilename}`}
                     />
                  }
               </Grid>
               <Grid container item xs={6} direction='row' alignItems='center'>
                  <Typography variant='h6' >
                     { classPhoto.classPhotoCaption }
                  </Typography>
               </Grid>
               <Grid container item xs={1} alignItems='center'>
                  <IconButton onClick={() => handleEdit(classPhoto._id)}>
                     <Typography variant='h6'>
                           Edit
                           </Typography>
                  </IconButton>
               </Grid>
               <Grid container item xs={1}>
                  {index === 0 ?
                  <IconButton disabled>
                     <ArrowUpwardIcon />
                  </IconButton>
                  :
                  <IconButton onClick={ () => handleMoveUp(index) }>
                     <ArrowUpwardIcon />
                  </IconButton>
                  }
                  { index === classPhotoArray.length - 1 ?
                  <IconButton disabled>
                     <ArrowDownwardIcon />
                  </IconButton>
                  :
                  <IconButton onClick={ () => handleMoveDown(index) }>
                     <ArrowDownwardIcon />
                  </IconButton>
                  }
               </Grid>
         </Grid>)
      } )
   }

   return (
      <Box>
         <Container>
               { classPhotosToDisplay }
         </Container>
      </Box>
   )
}

ShowClassPhotoToManage.propTypes = {
    classPhoto: PropTypes.object,
    getPhotoIdToEdit: PropTypes.func,
    movePhotoEntryDown: PropTypes.func,
    movePhotoEntryUp: PropTypes.func
}

export default ShowClassPhotoToManage
