import React, { useEffect, useState } from 'react'
import { photoDims } from '../config'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditBioPhoto = ({photoToEdit, updatedDataForDB}) => {

    const [ caption, setCaption ] = useState('')
    const [ filename, setFilename ] = useState('')
    const [ bioPhotoWidth, setBioPhotoWidth ] = useState('')

    useEffect( () => {
        setBioPhotoWidth(photoToEdit[0].bioPhotoWidth ? photoToEdit[0].bioPhotoWidth : photoDims.bioPhotoWidth)
        setCaption(photoToEdit[0].bioPhotoCaption ? photoToEdit[0].bioPhotoCaption : '')
        setFilename(photoToEdit[0].bioPhotoFilename ? photoToEdit[0].bioPhotoFilename : '')
    }, [photoToEdit] )

    const handleCaptionChange = (e) => {
        setCaption(e.target.value)
    }

    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    const handleBioPhotoWidthChange = (e) => {
        setBioPhotoWidth(e.target.value)
    }

    const handleSubmit = () => {
        updatedDataForDB({
            caption: caption,
            filename: filename,
            bioPhotoWidth: bioPhotoWidth
        })
    }

    return (
        <Grid style={{marginTop: '3rem'}}>
            <Container>
                <Grid>
                    <form>
                        <Typography variant='h4' >
                            Edit Bio Photo
                        </Typography>
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={12} sm={10} style={{marginTop: '1rem'}} >
                                <TextField value={filename} label='Filename' style={{width: '100%'}} onChange={handleFilenameChange} />
                            </Grid>
                            <Grid item xs={12} sm={1} style={{marginTop: '1rem'}} >
                                <TextField value={bioPhotoWidth} label='Photo Width' style={{width: '100%'}} onChange={handleBioPhotoWidthChange} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <TextareaAutosize value={caption} minRows={4} style={{width: '100%', marginTop: '2rem'}} onChange={handleCaptionChange} />
                        </Grid>
                        <Grid container item justifyContent='flex-start' style={{marginTop: '1.5rem'}} >
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </Grid>
    )
}

EditBioPhoto.propTypes = {
    photoToEdit: PropTypes.array,
    updatedDataForDB: PropTypes.func
}

export default EditBioPhoto
