import React, { useEffect }                     from 'react'
import axios                                    from 'axios'
import { bubbleSortArrayOfObjects }             from '../Helpers'
import { CLIENT_PUBLIC_TOKEN, node_server }     from '../Helpers'
import PropTypes                                from 'prop-types'
import { store }                                from '../Redux/Store'

const GetBioListPartial = ({getBioListPartial}) => {

   const { goodLogin } = store.getState()

   useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'bio/GetAllBiosPartial')
         .then( res => res.data )
         .then( data => {
            bubbleSortArrayOfObjects(data, 'lastName')

               /* Sends back the appropriate bios based on whether the user is logged in or not */
               if( goodLogin ){
                  /* Sends the bio list back to <BioList /> */
                  getBioListPartial(data)
               }
               else {
                  const filteredData = data.filter( bio => {
                     if( bio.publicBio ){ return bio }
                     else{ return null}
                  } )
                  /* Sends the filtered bio list back to <BioList /> */
                  getBioListPartial(filteredData)
               }

               /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
                  after a GET was made to the Node server. */
               axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
   }, [getBioListPartial, goodLogin] )

   return (
      <div>
         
      </div>
   )
}

GetBioListPartial.propTypes = {
    getBioListPartial: PropTypes.func
}

export default GetBioListPartial
