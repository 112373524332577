/*
 * This takes a class and retrieves those class members from the PHP server,
 * sends grads back to Parent Component where the data will be parsed.
 * 
 * Called by <ShowGradsFromOneClass />
 */
import React, { useEffect } from 'react'
import { CLIENT_PUBLIC_TOKEN, node_server } from '../Helpers'
import axios from 'axios'
import PropTypes from 'prop-types'

const GradsForOneClass = ({getGradsForOneClass, tpsClass}) => {

  /* Calls the PHP server and gets all alumni members of the selected class,
    gets parsed into first and last name below */
    useEffect(() => {

    axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
    axios.get(node_server + `grad/getGradsForOneClass/${tpsClass}`)
        .then(res => res.data)
        .then(data => {
            getGradsForOneClass(data.allGrads)
            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
        })
}, [tpsClass])

  return (
    <div>
      
    </div>
  )
}

GradsForOneClass.propTypes = {
  getGradsForOneClass: PropTypes.func,
  tpsClass: PropTypes.string
}

export default GradsForOneClass
