/*
 * This Components gets ALL users from the Node server and tbl_auth
 * This Components USED to get ALL users from the PHP server and tbl_auth
 * 
 * Called by:
 *    <YearbookTPSClasses />
 */
import React, { useEffect, useState }           from 'react'
import axios                                    from 'axios'
import { bubbleSortArray }                      from '../Helpers'
import { CLIENT_PUBLIC_TOKEN, node_server }     from '../Helpers'
import PropTypes                                from 'prop-types'
import { store }                                from '../Redux/Store'

const AllGradsFromTblAuth = ({getAllGrads, searchString}) => {

   const [ classArray, setClassArray ] = useState([])
   const { jwt } = store.getState()

  /* Gets all grads from PHP and tbl_auth and stores in classArray in local state
     This useEffect only runs once */
   useEffect(() => {
      let formData = new FormData()

   //  formData.append("jwt", jwt)
   axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
   axios.get(node_server + 'grad/getAllGradsFromTblAuth')
      .then(res => res.data)
      .then(data => {
         if (data.msg=== 'User is not properly authenticated') {
            // console.log('AllGradsFromTblAuth User is not properly authenticated')
         } 
         else if (data.msg === 'Expired token') {
            // console.log('AllGradsFromTblAuth User session has timed out')
         } else {
         /* This breaks the username into pieces and adds firstName, lastName and tpsClass to the object
            and it also performs first letter capitalization */
            const tempArray = data.filter(g => {
            if (g.userId > 9) {
               const breakUsernameIntoPieces = g.username.split('.')

               /* These are non-grads included for some reason, so they don't have firstName.lastName.tpsClass */
               if (breakUsernameIntoPieces.length === 2) {
                  return null
               }
               else if (breakUsernameIntoPieces.length === 3) {
                  g.firstName = breakUsernameIntoPieces[0][0].toLocaleUpperCase() + breakUsernameIntoPieces[0].slice(1,breakUsernameIntoPieces[0].length)
                  g.lastName = breakUsernameIntoPieces[1][0].toLocaleUpperCase() + breakUsernameIntoPieces[1].slice(1,breakUsernameIntoPieces[1].length)
               } else {
                  g.firstName = breakUsernameIntoPieces[0].toLocaleUpperCase() + '.' + breakUsernameIntoPieces[1].toLocaleUpperCase() + '.'
                  g.lastName = breakUsernameIntoPieces[2][0].toLocaleUpperCase() + breakUsernameIntoPieces[2].slice(1,breakUsernameIntoPieces[2].length)
               }
               g.tpsClass = breakUsernameIntoPieces[breakUsernameIntoPieces.length - 1].toLocaleLowerCase()
               return g
            }
            return null
            })
            setClassArray(tempArray)
         }
      })
   }, [])

/* This parses the local state classArray based on searchString */
useEffect(() => {
   let classList = []

   /* Gets all grad usernames based on filter in .includes() */
   let gradsFromDB = classArray.filter(g => {
      if (searchString === 'allClasses' && g.userId > 9) { return g }
      if (g.username.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) && g.userId > 9) { return g }
      return null
   })

   /* classes is an array that has all the classes from the selected group listed once in lower case,
         not in order */
   gradsFromDB.filter(g => {
      const classNumber = g.username.split('.')
      classNumber[classNumber.length - 1] = classNumber[classNumber.length - 1].toLocaleLowerCase()
      if (!classList.includes(classNumber[classNumber.length - 1])) {
         classList.push(classNumber[classNumber.length - 1])
      }
      return null
   })

   bubbleSortArray(classList)

   /* Sort grads into classes in a 2D array, name are almost entirely in alphabetical order
         due to their order in the db */
   const gradsByClass = []
   for (let i = 0; i < classList.length; i++) {
      for (let j = 0; j < gradsFromDB.length; j++) {
         if (gradsFromDB[j].username.toLocaleLowerCase().includes(classList[i])) {
         if (gradsByClass.length === i) {
            gradsByClass.push([])
         }
         gradsByClass[i].push(gradsFromDB[j])
         }
      }
   }
   getAllGrads(gradsByClass)
   }, [getAllGrads, classArray, searchString])

   return (
      <div>
         
      </div>
   )
}

AllGradsFromTblAuth.propTypes = {
   getAllGrads: PropTypes.func,
   searchString: PropTypes.string
}

export default AllGradsFromTblAuth