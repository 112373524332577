import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const EditClassPatch = ({patchToEdit, updatedDataForDB}) => {

    const [ caption, setCaption ] = useState('')
    const [ filename, setFilename ] = useState('')


    useEffect( () => {
        setCaption(patchToEdit.classPatchCaption)
        setFilename(patchToEdit.classPatchFilename)
    }, [patchToEdit] )

    const handleCaptionChange = (e) => {
        setCaption(e.target.value)
    }

    const handleFilenameChange = (e) => {
        setFilename(e.target.value)
    }

    const handleSubmit = () => {
        updatedDataForDB({
            caption: caption,
            filename: filename
        })
    }

    return (
        <Grid style={{marginTop: '3rem'}}>
            <Container>
                <Grid>
                    <form>
                        <Typography variant='h4' >
                            Edit Class Patch
                        </Typography>
                        <Grid container justifyContent='space-between'>
                            <Grid item xs={12} sm={10} style={{marginTop: '1rem'}} >
                                <TextField value={filename} label='Filename' placeholder='Enter Filename Here' style={{width: '100%'}} onChange={handleFilenameChange} />
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent='flex-start' style={{marginTop: '1.5rem'}} >
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Container>
        </Grid>
    )
}

EditClassPatch.propTypes = {
    patchToEdit: PropTypes.object,
    updatedDataForDB: PropTypes.func
}

export default EditClassPatch
